<template>
    <div class="recommendable-person">
        <StackRouterHeaderBar />

        <main class="main">
            <FeedbackTop class="m-b-40" :title="'다른 이성에게<br />소개해줘도 괜찮은 분인가요?'" />
            <FeedbackOptionItems v-model="items" @select="onSelectItem" />
        </main>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import FeedbackOptionItems from './components/FeedbackOptionItems'
import datingService from '@/services/dating'

export default {
    name: 'RecommendablePersonPage',
    components: { FeedbackTop, FeedbackOptionItems },
    props: {
        type: String,
        user: Object,
    },
    data: () => ({
        items: [],
    }),
    methods: {
        init() {
            const options = this.$store.getters.feedbackOptions || []
            const category = this.type === 'meet' ? 'introduce_to_other_m' : 'introduce_to_other'

            this.items = options
                .filter(option => option.category === category)
                .map(option => {
                    this.$set(option, 'selected', false)
                    return option
                })
        },
        async onSelectItem(idx) {
            this.$store.commit('setSelectedFeedbacks', {
                category: 'recommendable',
                feedbacks: [{ id: this.items[idx].id }],
            })

            if (idx === 0) {
                // 소개 ㅇㅋ
                this.$stackRouter.push({
                    name: 'RecommendableReasonPage',
                    props: {
                        type: this.type,
                        user: this.user,
                    },
                })
            } else {
                if (this.type === 'meet') {
                    this.$stackRouter.push({
                        name: 'UnrecommendableReasonPage',
                        props: {
                            type: this.type,
                            user: this.user,
                        },
                    })
                } else {
                    try {
                        this.$loading(true)
                        const { payload, dating } = await this.$store.dispatch('prepareFeedbackPayload')
                        await datingService.feedback(dating.id, payload)
                        await this.$store.dispatch('loadDatingSuccess')

                        this.$toast.success('COMPLETE_SUBMIT')
                    } catch (e) {
                        this.$toast.error(e.data)
                    } finally {
                        this.$loading(false)
                        this.$stackRouter.clear()
                    }
                }
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.recommendable-person {
    .main {
        padding: 0 16px;
    }
}
</style>
